const appConfig = {
  "name": "commencement-checkin",
  "version": "1.0.0",
  "stage": "master",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_bCu6BU8d2",
        "userPoolWebClientId": "4ru7fonbhhstbmuidtlofvdjh2",
        "oauth": {
          "domain": "atlas-auth.mit.edu",
          "scope": [
            "profile",
            "openid",
            "digital-id/user",
            "digital-id/search",
            "user/all"
          ],
          "redirectSignIn": "https://commencement-checkin.atlas-apps.mit.edu/",
          "redirectSignOut": "https://commencement-checkin.atlas-apps.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api.mit.edu",
        "paths": {
          "digital-id": "digital-id-v1"
        }
      }
    }
  ]
};
export default appConfig;