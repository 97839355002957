import { appInitialized } from '@mit/aws-react'
import { useGetProfileQuery } from 'api/DigitalIdApi'
import { useEffect, useState } from 'react'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)

  /**
   * Use Skip Option to not query api on initial load. Once state flag is flipped to true, queries will load.
   * Use useEffect to trigger when everything you need has loaded and any auth check has passed/not passed
   *
   * Below is just an example. The profile component handles fetching it's own data
   */
  const { data: profile } = useGetProfileQuery(undefined, {
    skip: !initializeApi
  })

  useEffect(() => {
    // if (profile?.krb_name != null) {
    appInitialized({
      isLoading: false,
      hasAccess: true,
      hasError: false
    })
    // }
  }, [profile])

  const initializeApp = async (): Promise<void> => {
    setInitializeApi(true)
  }

  return { initializeApp }
}
